import { InterpolationSource } from "./data";
import { LOCAL_STORAGE } from "../../../Utils/const";

const roundMe = (value) => Math.round((value) * 100) / 100;

export const getGroupId = () => {
    const id = localStorage.getItem(LOCAL_STORAGE.GROUP_ID)
    if (!id) return ""
    return id
}

export function findPositionBeforeAfter(value, arr) {
    let result;
    let pos1;
    let pos2;
    arr.forEach((a, i) => {
        const minus = value - a;
        if (a <= value && (!result || minus < result)) {
            result = minus;
            pos1 = i;
            pos2 = i + 1;
        }
    })
    return [pos1, pos2]
}

export function OneWayInterpolation(value, arr1, arr2) {
    const [x1, x2] = findPositionBeforeAfter(value, arr1);
    const h1 = value - arr1[x1];
    const h2 = arr1[x2] - value;
    const result = (h1 * arr2[x2] + h2 * arr2[x1]) / (h1 + h2);
    return result
}

export function CalculateFlow(dr_value, z_value, group_id = getGroupId()) {
    if (isNaN(z_value) || isNaN(dr_value)) return 0;
    if (dr_value < 0 || z_value < 0) return 0;

    const currentInterpol = InterpolationSource.find(p => p.group_id === group_id);
    if (!currentInterpol) return;

    const { z, dr, value } = currentInterpol.D;

    const dr_max = dr[dr.length - 1]
    const z_max = z[z.length - 1]
    if (dr_value > dr_max) dr_value = dr_max
    if (z_value > z_max) z_value = z_max

    if (dr.includes(dr_value) && z.includes(z_value)) {
        const indexX = dr.findIndex(a => a === dr_value)
        const indexY = z.findIndex(a => a === z_value)
        const q = value[indexY][indexX]
        return roundMe(q)
    } else if (dr.includes(dr_value)) {
        const index = dr.findIndex(a => a === dr_value)
        const q = OneWayInterpolation(z_value, z, value[index])
        return roundMe(q)
    } else if (z.includes(z_value)) {
        const index = z.findIndex(a => a === z_value)
        const q = OneWayInterpolation(dr_value, dr, value[index])
        return roundMe(q)
    }

    let pos = {};

    const z_pos = findPositionBeforeAfter(z_value, z);
    pos.z1 = z_pos[0];
    pos.z2 = z_pos[1];
    const dr_pos = findPositionBeforeAfter(dr_value, dr);
    pos.dr1 = dr_pos[0];
    pos.dr2 = dr_pos[1];

    const dr_h1 = roundMe(dr_value - dr[pos.dr1])
    const dr_h2 = roundMe(dr[pos.dr2] - dr_value)

    const dr1_x1 = value[pos.dr1][pos.z1]
    const dr1_x2 = value[pos.dr2][pos.z1]

    const dr1 = roundMe((dr_h1 * dr1_x2 + dr_h2 * dr1_x1) / (dr_h1 + dr_h2))

    const dr2_x1 = value[pos.dr1][pos.z2]
    const dr2_x2 = value[pos.dr2][pos.z2]
    const dr2 = roundMe((dr_h1 * dr2_x2 + dr_h2 * dr2_x1) / (dr_h1 + dr_h2))

    const z_h1 = roundMe(z_value - z[pos.z1]);
    const z_h2 = roundMe(z[pos.z2] - z_value);

    const x = roundMe((z_h1 * dr2 + z_h2 * dr1) / (z_h1 + z_h2))

    return x;
}

export function CalculateDrain(q_value, z_value, group_id = getGroupId()) {
    if (isNaN(z_value) || isNaN(q_value)) return 0;
    q_value = roundMe(q_value);

    const currentInterpol = InterpolationSource.find(p => p.group_id === group_id)
    if (!currentInterpol) return;

    const { z, dr, value } = currentInterpol.D;

    let pos = {
        z: null,
        z1: null,
        z2: null,
        dr1: null,
        dr11: null,
        dr12: null,
        dr2: null,
        dr21: null,
        dr22: null
    }

    z.forEach((c, i) => {
        const minus = z_value - c;
        if (c <= z_value && (!pos.z || minus < pos.z)) {
            pos.z = minus;
            pos.z1 = i;
            pos.z2 = i + 1;
        }
    })

    value.forEach((v, i) => {
        const minus1 = q_value - v[pos.z1];
        if (v[pos.z1] <= q_value && (!pos.dr1 || minus1 < pos.dr1)) {
            pos.dr1 = minus1;
            pos.dr11 = i;
            pos.dr12 = i + 1;
        }
        const minus2 = q_value - v[pos.z2];
        if (v[pos.z2] <= q_value && (!pos.dr2 || minus2 < pos.dr2)) {
            pos.dr2 = minus2;
            pos.dr21 = i;
            pos.dr22 = i + 1;
        }
    })

    const dr1_h1 = roundMe(q_value - value[pos.dr11][pos.z1])
    const dr1_h2 = roundMe(value[pos.dr12][pos.z1] - q_value);
    const dr1 = roundMe((dr1_h1 * dr[pos.dr12] + dr1_h2 * dr[pos.dr11]) / (dr1_h1 + dr1_h2))

    const dr2_h1 = roundMe(q_value - value[pos.dr21][pos.z2]);
    const dr2_h2 = roundMe(value[pos.dr22][pos.z2] - q_value);
    const dr2 = roundMe((dr2_h1 * dr[pos.dr22] + dr2_h2 * dr[pos.dr21]) / (dr2_h1 + dr2_h2))

    const z_h1 = roundMe(z_value - z[pos.z1]);
    const z_h2 = roundMe(z[pos.z2] - z_value);
    const x = roundMe((z_h1 * dr2 + z_h2 * dr1) / (z_h1 + z_h2))

    return x;
}