import { LOCAL_STORAGE } from "./const"
import Hypothesis_DD from "../App/Pages/Help/Components/Hypothesis/Hypothesis_DD";
import Hypothesis_SL from "../App/Pages/Help/Components/Hypothesis/Hypothesis_SL";
import Hypothesis_CM from "../App/Pages/Help/Components/Hypothesis/Hyphothesis_CM";

const DrainParameter = ['KKKXW2TW'];

const HelpSpin = ['KKKXW2TW', 'WFYOUGL1']

const OneNumberDrain = ['KKKXW2TW'];

const TwoNumberDrain = ['KKKXW2TW', 'WFYOUGL1'];

const ThreeNumberDrain = ['KKKXW2TW'];

function getGroupId() {
    const id = localStorage.getItem(LOCAL_STORAGE.GROUP_ID)
    if (!id) return ""
    return id
}

export const hasDrainParameter = DrainParameter.includes(getGroupId())
export const hasHelpSpin = HelpSpin.includes(getGroupId())
export const hasOneNumberDrain = OneNumberDrain.includes(getGroupId())
export const hasTwoNumberDrain = TwoNumberDrain.includes(getGroupId())
export const hasThreeNumberDrain = ThreeNumberDrain.includes(getGroupId())

export function ViewHypothesis(props) {
    const group_id = getGroupId()
    switch (group_id) {
        case 'KKKXW2TW':
            return <Hypothesis_SL {...props} />
        case 'WFYOUGL1':
            return <Hypothesis_DD {...props} />
        case 'IXC8QY8W':
            return <Hypothesis_CM {...props} />
        default:
            return <div>Không có</div>
    }
}