import { useEffect, useState, Children } from "react";
import { BuildChartData, adjustRAData, METRIC_GROUPS } from "../../Components/Chart/Component/chart_data";
import monitorService from "../Monitor/Shared/MonitorService";
import LineChart from "../../Components/Chart/Component/LineChart";
import BarChart from "../../Components/Chart/Component/BarChart";
import MultiLineChart from "../../Components/Chart/Component/MultiLineChart";
import { getMinutes } from "date-fns";

const ChartComponent = ({ boxs, boxSelected, time }) => {
    const [charts, setCharts] = useState();
    const [metrics, setMetrics] = useState([]);

    useEffect(() => {
        if (!boxs || !boxSelected) return;
        const currentBox = boxs.find(b => b.id === boxSelected);
        setMetrics([...currentBox.metrics])
    }, [boxs, boxSelected])

    useEffect(() => {
        if (!boxSelected || !metrics || metrics.length === 0) return;
        monitorService.getRecordByBox(boxSelected, time)
            .then(res => {
                const data = res.data.records.filter(r => getMinutes(r.t * 1000) === 0 || getMinutes(r.t * 1000) === 30);
                const chart = BuildChartData(data, boxs.find(b => b.id === boxSelected));
                const ajustChart = adjustRAData(chart.filter(r => r));
                setCharts(ajustChart)
            })
            .catch(err => console.log(err));
    }, [boxSelected, time, metrics]);

    return <div className="ChartComponent">
        <div className="list-chart">
            {charts && Children.toArray(charts.map(t => {
                if (t.type === 'line') return <div className="chart-container">
                    <p>Biểu đồ dữ liệu {METRIC_GROUPS.find(m => m.metric === t.metric).label}</p>
                    <div className="chart-item">
                        <LineChart min={0} height='100%' data={t} />
                    </div>
                </div>
                if (t.type === 'bar') return <div className="chart-container">
                    <p>Biểu đồ dữ liệu {METRIC_GROUPS.find(m => m.metric === t.metric).label}</p>
                    <div className="chart-item">
                        <BarChart height='100%' data={t} />
                    </div>
                </div>
                 if (t.type === 'multiLine') return <div className="chart-container">
                 <p>Biểu đồ dữ liệu {METRIC_GROUPS.find(m => m.metric === t.metric).label}</p>
                 <div className="chart-item">
                     <MultiLineChart min={0} height='100%' data={t} />
                 </div>
             </div>
            }))}
        </div>
    </div>
}

export default ChartComponent;