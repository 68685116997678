import { Children, useState } from "react";
import { Paper, Box, Typography, Skeleton } from "@mui/material";
import dashboardSevice from "../../Shared/DashboardService";
import { ONE_SEC } from "../../../../../Utils/const";

const CameraControl = (props) => {
    const { cameras, isLoading } = props;

    const [cameraErr, setCameraErr] = useState([]);

    let isFullScreen = false;

    const handleFullScreen = (index) => {
        const img = document.getElementById(`camera${index}`);
        isFullScreen = !isFullScreen;
        if (isFullScreen) {
            img.requestFullscreen();
        } 
        if (document.fullscreenElement !== null) {
            document.exitFullscreen();
        }
    }

    const checkCamera = () => {
        const imgs = document.getElementsByClassName("cameras-stream");

        setTimeout(() => {
            const errors = [...imgs].filter(el => {
                if (el.currentSrc === "") return el;
            });
            setCameraErr(errors);
        }, 60 * ONE_SEC);
    }

    const handleLoadImage = () => {
        checkCamera();
    }

    checkCamera();
    cameraErr.length !== +0 && cameraErr.forEach(i => {
        i.src = "";
        i.alt = "Có lỗi xảy ra";
    });

    return (
        <div className="br-6 Camera">
            <Paper
                elevation={8}
                sx={{
                    boxShadow: "none",
                    borderTop: '1.5px solid',
                    borderRight: '1.5px solid',
                    borderBottom: '3px solid',
                    color: ' #0000001f',
                    borderLeft: 'none',
                    borderRadius: "5px"
                    // width: "99%",
                    // height: "94.6vh"
                }}
                className="Camera"
            >
                <div className="middle top">
                    <Typography
                        className="title"
                        sx={{
                            fontSize: {xs: '16px', md: '18px'},
                        }}
                    >
                        Camera
                    </Typography>
                </div>
                <div className="content">
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            overflow: "hidden",
                            overflowY: "scroll",
                        }}
                    >
                        {!isLoading ? Children.toArray(cameras.map((c, i) => {
                            return (
                                <>
                                    <img
                                        alt="camera"
                                        id={`camera${i}`}
                                        className="cameras-stream pointer"
                                        src={dashboardSevice.getDataCamera(c)}
                                        style={{ height: "32%", marginBottom: "2%", marginLeft: "1%" }}
                                        onClick={() => handleFullScreen(i)}
                                        onLoad={handleLoadImage}
                                    />
                                </>
                            )
                        })) : <>
                            {Children.toArray([...Array(3)].map((_, i) => {
                                return (
                                    <Skeleton
                                        variant="rectangular"
                                        className="mb-26 ml-3"
                                        style={{ height: "32%" }}
                                        onLoad={(e) => handleLoadImage(e, i)}
                                    />
                                )
                            }))}
                        </>}
                    </Box>
                </div>
                <div className="bot">
                    <Typography className="fontsz-14 end pr-17">
                        {cameras.length !== +0 ?
                            cameras.length >= 3 ?
                                <>Hiển thị 1-3 / <b>{cameras.length}</b> kết quả </> :
                                <>Hiển thị 1-{cameras.length} / <b>{cameras.length}</b> kết quả </> :
                            <>Hiển thị 1-3 / <b>8</b> kết quả</>
                        }
                    </Typography>
                </div>


            </Paper>

        </div>
    )
}

export default CameraControl;