import { InterpolationSource } from "./data";
import { LOCAL_STORAGE } from "../../../Utils/const";

export const roundMe = (value) => Math.round((value) * 100) / 100;

const getGroupId = () => {
    const id = localStorage.getItem(LOCAL_STORAGE.GROUP_ID)
    if (!id) return ""
    return id
}

export function findPositionBeforeAfter(value, arr) {
    let result;
    let pos1;
    let pos2;
    arr.forEach((a, i) => {
        const minus = value - a;
        if (a <= value && (!result || minus < result)) {
            result = minus;
            pos1 = i;
            pos2 = i + 1;
        }
    })
    return [pos1, pos2]
}

export function OneWayInterpolation(value, arr1, arr2) {
    const [x1, x2] = findPositionBeforeAfter(value, arr1);
    const h1 = value - arr1[x1];
    const h2 = arr1[x2] - value;
    const result = (h1 * arr2[x2] + h2 * arr2[x1]) / (h1 + h2);
    return result
}


function CalculateInterpolation(x_value, y_value, arrX, arrY, values) {
    if(x_value < 0 || y_value < 0 ) return 0
    if(arrX.includes(x_value) && arrY.includes(y_value)) {
        const indexX = arrX.findIndex(a => a === x_value)
        const indexY = arrY.findIndex(a => a === y_value)
        const z = values[indexY][indexX]
        return z
    } else if(arrX.includes(x_value)) {
        const index = arrX.findIndex(a => a === x_value)
        const current_arr = values.map((v,i) => v[index])
        const z = OneWayInterpolation(y_value, arrY, current_arr)
        return z
    }else if(arrY.includes(y_value)) {
        const index = arrY.findIndex(a => a === y_value)
        const z = OneWayInterpolation(x_value, arrX, values[index])
        return z
    }
    const [x1, x2] = findPositionBeforeAfter(x_value, arrX);
    const [y1, y2] = findPositionBeforeAfter(y_value, arrY);

    const y_h1 = y_value - arrY[y1];
    const y_h2 = arrY[y2] - y_value;

    const z1 = (y_h1 * values[y2][x1] + y_h2 * values[y1][x1]) / (y_h1 + y_h2)
    const z2 = (y_h1 * values[y2][x2] + y_h2 * values[y1][x2]) / (y_h1 + y_h2)

    const x_h1 = x_value - arrX[x1];
    const x_h2 = arrX[x2] - x_value;

    const z = (x_h1 * z2 + x_h2 * z1) / (x_h1 + x_h2);
    return z
}

export function CalculateFlow(number_of_drain, z_value, dr_value, group_id = getGroupId()) {
    if(isNaN(z_value) || isNaN(dr_value)) return 0;
    const currentInterpol = InterpolationSource.find(p => p.group_id === group_id)
    if (!currentInterpol || !z_value || !dr_value) return 0;

    const { z, dr } = currentInterpol.S;

    if(dr_value > dr[dr.length - 1]) dr_value = dr[dr.length - 1];
    if(z_value > z[z.length - 1]) z_value = z[z.length - 1];

    const ZD = currentInterpol?.ZD || 0;
    const delta_z = z_value - ZD;
    if(dr_value > delta_z) dr_value = delta_z;

    const values = currentInterpol.S["value" + number_of_drain];
    const result = CalculateInterpolation(z_value, dr_value, z, dr, values)

    return roundMe(result)
}

function findPositionReverse(x1, x2, q_value, values) {
    let result1, result2;
    let pos11, pos12, pos21, pos22;

    values.forEach((v, i) => {
        const minus1 = q_value - v[x1];
        if (v[x1] <= q_value && (!result1 || minus1 < result1)) {
            result1 = minus1;
            pos11 = i;
            pos12 = i + 1;
        }
        const minus2 = q_value - v[x2];
        if (v[x2] <= q_value && (!result2 || minus2 < result2)) {
            result2 = minus2;
            pos21 = i;
            pos22 = i + 1;
        }
    })

    return [pos11, pos12, pos21, pos22]
}

function CalculateInterpolationReverse(x_value, q_value, arrX, arrY, values) {
    if(arrX.includes(x_value)) {
        const index = arrX.findIndex(a => a === x_value)
        const current_arr = values.map((v,i) => v[index])
        if(current_arr.includes(q_value)) {
            const indexY = current_arr.findIndex(a => a === q_value)
            const result = arrY[indexY]
            return result
        }
        const result = OneWayInterpolation(q_value, current_arr, arrY)
        return result
    }

    const [x1, x2] = findPositionBeforeAfter(x_value, arrX);
    const [y11, y12, y21, y22] = findPositionReverse(x1, x2, q_value, values);

    const y1_h1 = q_value - values[y11][x1];
    const y1_h2 = values[y12][x1] - q_value;
    const y1 = (y1_h1 * arrY[y12] + y1_h2 * arrY[y11]) / (y1_h1 + y1_h2);

    const y2_h1 = q_value - values[y21][x2];
    const y2_h2 = values[y22][x2] - q_value;
    const y2 = (y2_h1 * arrY[y22] + y2_h2 * arrY[y21]) / (y2_h1 + y2_h2);

    const x_h1 = x_value - arrX[x1];
    const x_h2 = arrX[x2] - x_value;

    const result = (x_h1 * y2 + x_h2 * y1) / (x_h1 + x_h2);

    return result;
}

export function CalculateDrain(number_of_drain, z_value, q_value, group_id = getGroupId()) {
    if(isNaN(z_value) || isNaN(q_value)) return 0;
    q_value = roundMe(q_value);
  
    const currentInterpol = InterpolationSource.find(p => p.group_id === group_id)
    if (!currentInterpol) return;

    const { z, dr } = currentInterpol.S;

    const values = currentInterpol.S["value" + number_of_drain];

    const result = CalculateInterpolationReverse(+z_value, q_value, z, dr, values)

    return roundMe(result);
}



const Zhl = [112.50, 112.70, 112.90, 113.10, 113.40, 113.70, 114.00, 114.30, 114.60, 115.00, 115.30, 115.60, 116.00, 116.30, 116.60, 117.00, 117.30, 117.60, 118.00, 119.00, 120.00, 121.00, 122.00];
const Qhl = [0, 9.51, 26.9, 49.4, 90.8, 140, 195, 257, 324, 420, 498, 580, 696, 787, 883, 1015, 1118, 1248, 1371, 1494, 1617, 1740, 1863]


export function CalculateDown(q_value) {
    if(!q_value) return 0;
    const [x1, x2] = findPositionBeforeAfter(q_value, Qhl)
    
    const h1 = q_value - Qhl[x1];
    const h2 = Qhl[x2] - q_value;

    const z = (Zhl[x1] * h2 + Zhl[x2] * h1) / (h1 + h2)
    
    return roundMe(z);
}

export function CalculateFree(z_value) {
    if(z_value < 129.5 || z_value > 131.5 || isNaN(z_value)) return 0;
    if(+z_value === 131.5) return 200;
    const z = [129.5, 130, 130.5, 131, 131.5];
    const q = [0, 26, 71, 130, 200];
    const result = OneWayInterpolation(z_value, z, q)
    return result;
}

export function CalculateSpinFree(z_value, group_id = getGroupId()) {
    if(isNaN(z_value)) return 0;
    const currentInterpol = InterpolationSource.find(p => p.group_id === group_id)
    if (!currentInterpol) return 0;
    const { z, value } = currentInterpol.Free;
    const result = OneWayInterpolation(z_value, z, value)
    if(isNaN(result)) return 0;
    return result
}