const ProgressBar = (props) => {
    const { bgColor, completed, value } = props;

    const percent = value !== +0 ? ((completed / value) * 100).toFixed(2) : +0;
    
    const progressBar = {
        height: 15,
        width: "95%",
        backgroundColor: "#FFFFFF",
        borderRadius: 13,
        outline: `1px solid ${bgColor}`
    }

    const filterBar = {
        height: "100%",
        width: percent >= 5 ? `${percent}%` : 0,
        backgroundColor: bgColor,
        borderRadius: "inherit",
        // textAlign: "center",
        display: "flex",
        alignItems: "center"
    }

    const label = {
        color: "white",
        fontWeight: 700,
        fontSize: 14,
        paddingLeft: 8

    }

    const labelMin = {
        color: completed !== +0 ? bgColor : "white",
        fontWeight: 700,
        fontSize: 14,
        paddingLeft: 8
    }

    return (
        <div style={progressBar}>
            <div style={filterBar}>
                <span style={filterBar.width === +0 ? labelMin : label}>{`${completed}`}</span>
            </div>
            {/* <span>{`${percent}%`}</span> */}
        </div>
    )
}

export default ProgressBar;