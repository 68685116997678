import { makeStyles } from "@material-ui/core/styles";
import Grid from '@mui/material/Grid';
import FormControl from "@mui/material/FormControl";
import MenuItem from '@mui/material/MenuItem';
import TextField, { } from "@material-ui/core/TextField";
import Select from '@mui/material/Select';

import { APP_TITLE, DOMAIN } from "../App/Utils/CustomSideBar";
import { CHECK_EMPTY_STRING } from "../../../Utils/string";
import { BOXGROUP_ID, ID_GROUPS } from "../../../Utils/const";
import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ModalConfirm from "../ModalConfirm/ModalConfirm";

const color = "red";
const useStyles = makeStyles(() => ({
    select: {
        "&:before": {
            border: "none",
            outline: "none"
        },
        "&:after": {
            border: "none",
            outline: "none"
        },
        color: "#EA4335",
        fontWeight: 700,
    },
    icon: {
        fill: color
    },
    iconSelect: {
        color: color
    },
}));

/**
 * @param {*} string 
 */
const CustomBoxGroupName = (string) => {
    if (typeof string === "string") {
        if (string.includes("-")) {
            const arrString = string.split("-");
            return arrString[1];
        }
        const arrString = string.split(" ");
        arrString.reverse();
        return `${arrString[1]} ${arrString[0]}`.toUpperCase();
    }
    return "";
}

const MenuApp = (props) => {
    const { groups, groupId, time, domain, parentCallBack } = props;
    const classes = useStyles();
    const [modal, setModal] = useState({})

    const handleChangeBoxGroup = (e) => {
        const boxgroup_id = e.target.value;
        parentCallBack(boxgroup_id);
    }

    const handleChangeDate = (e, name) => {
        const now = e.target.value;
        parentCallBack("", { ...time, [name]: now });
    }

    const currentGroupId = window.localStorage.getItem('group_id')

    useEffect(() => {
        if(!groups || groups.length === 0) return
        props.handleChangeBoxSelected(groups[0].id)
    }, [groups])

    const OpenModal = () => {
        setModal({
            open: true,
            message: "Xác nhận xuất file!",
            title: "Thông báo"
        })
    }

    const handleModal = (answer) => {
        if(answer) props.handleExportExcel(true)
        setModal({...modal, open: false})
    }

    return (
        <>
            <Grid container className="align-center">
                {
                    [DOMAIN.ZONE, DOMAIN.GROUP, DOMAIN.SENSOR, DOMAIN.USER, DOMAIN.CAMERA, `${DOMAIN.MONITOR}/compute`].includes(domain) ?
                        <>
                            <Grid item xs={4} >
                                <Typography
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: 18,
                                        color: "#EA4335",
                                    }}
                                    className="pt-10 pl-50 upper"
                                >
                                    {APP_TITLE(domain)}
                                </Typography>
                            </Grid>
                        </>
                        :
                        <>
                            {[`${DOMAIN.MONITOR}/list`, DOMAIN.CHART].includes(domain) ?
                                <Grid container sx={{ margin: { xs: '15px 0', md: '0' } }}>
                                    {[ID_GROUPS.DOT, ID_GROUPS.KG].includes(currentGroupId)  &&
                                        <>
                                            <Grid item xs={2} sm={2} lg={1} sx={{ pr: "1%" }} className="end">
                                                <Typography
                                                    sx={{
                                                        fontWeight: 600,
                                                        fontSize: { xs: 12, sm: 14 },
                                                        color: "#444444"
                                                    }}
                                                >
                                                    Trạm:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={3} lg={2}>
                                                <select onChange={e => props.handleChangeBoxSelected(e.target.value)}
                                                    style={{
                                                        borderColor: 'rgba(0, 0, 0, 0.23)',
                                                        paddingLeft: '12px',
                                                        paddingTop: '7px',
                                                        marginTop: '-4px',
                                                        borderRadius: '5px',
                                                        paddingBottom: '1px',
                                                        maxWidth: '200px'
                                                    }}
                                                >
                                                    {groups.filter(g =>g.type !== 'AUDIO').map(g => {
                                                        return <option key={g.id} value={g.id}>{currentGroupId === ID_GROUPS.DOT ? g.name.slice(27) : g.name}</option>
                                                    })}
                                                </select>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={1} sm={2} lg={1} sx={{ pr: "1%" }} className="end">
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: { xs: 12, sm: 14 },
                                                color: "#444444"
                                            }}
                                        >
                                            Từ:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} lg={3}>
                                        <TextField
                                            type="date"
                                            variant="outlined"
                                            value={time.start}
                                            onChange={(e) => handleChangeDate(e, "start")}
                                            inputProps={{
                                                style: {
                                                    padding: 3
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2} sm={2} lg={1} sx={{ pr: "1%" }} className="end">
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: { xs: 12, sm: 14 },
                                                color: "#444444"
                                            }}
                                        >
                                            Đến:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={3} lg={2}>
                                        <TextField
                                            type="date"
                                            variant="outlined"
                                            value={time.end}
                                            onChange={(e) => handleChangeDate(e, "end")}
                                            inputProps={{
                                                style: {
                                                    padding: 3,
                                                }
                                            }}
                                        />
                                    </Grid>
                                    {[ID_GROUPS.DOT, ID_GROUPS.KG].includes(currentGroupId) && [`${DOMAIN.MONITOR}/list`].includes(domain) &&
                                        <>
                                            <Grid item xs={2} sm={2} lg={2} sx={{ pr: "1%" }} className="end">
                                                <div className="ctn-export">
                                                    <Button onClick={OpenModal} style={{ fontSize: '12px' }} variant="outlined">Xuất báo cáo</Button>
                                                    <ModalConfirm modal={modal} functionCallBack={handleModal} />
                                                </div>
                                            </Grid>
                                        </>
                                    }
                                </Grid> : <Grid item xs={8} />}
                        </>
                }
            </Grid>
        </>
    )
}

export default MenuApp;