import React from "react";
import { Popup } from "react-leaflet";


const SPECIAL_METRIC = "ICI";


const SensorPopup = (props) => {

    React.useEffect(() => {

        //console.log('sensor', props);
    })

    return <>
        <Popup>
            <div className="title" >Trạm sensor</div>
            <div className="content">
                {props.metric.metric === SPECIAL_METRIC ?
                    props.box.metrics.map((metric, i) => {
                        if (metric.metric === SPECIAL_METRIC) {
                            return <div key={i} className="item">
                                <span className="item-title">{metric.name}: </span>
                                {props.record && Object.keys(props.record).some(key => key === metric.code) ?
                                    props.record[metric.code] : "0"
                                }
                            </div>
                        } else return null;
                    })
                    : <div className="item">
                        <span className="item-title">{props.metric.name}: </span>
                        {props.record && Object.keys(props.record).some(key => key === props.metric.code) ?
                            props.record[props.metric.code] : "0"
                        }
                    </div>
                }
            </div>
        </Popup>
    </>
};

export default SensorPopup;