import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import { useState } from 'react';
import monitorService from '../../Pages/Monitor/Shared/MonitorService';

const FunctionButtons = (props) => {
    const [open, setOpen] = useState(false);
    const [box, setBox] = useState();
    const { parentCallBack, listBox, time } = props;

    const handleExportReport = () => {
        if(!box) return;
        const url = monitorService.exportCSV(box, time)
        window.location.assign(url);
        setBox("")
        setOpen(false)
    }

    const handleInputData = () => {
        parentCallBack(true);
    }

    return (
        <>
        <Grid container>
            <Grid xs={6.5} lg={10}>

                <Button
                    variant="contained"
                    sx={{ mr: 3 }}
                    onClick={() => setOpen(true)}
                >Xuất báo cáo</Button>
            </Grid>
            <Grid xs={5.5} lg={2} className='end'>
                <Button
                    variant="outlined"
                    onClick={handleInputData}
                >Nhập số liệu</Button>
            </Grid>
        </Grid>

            <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'md'}>
                <DialogTitle>Xuất file</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vui lòng chọn trạm mà bạn muốn xuất file dữ liệu! 
                    </DialogContentText>
                    <FormControl style={{ width: '80%' }}>
                        <InputLabel id="select-label">Trạm</InputLabel>
                        <Select labelId="select-label" value={box} label="Trạm" onChange={(e) => setBox(e.target.value)} >
                            {listBox && listBox.map(l => {
                                return <MenuItem selected={l.id === box ? true : false} key={l.id} value={l.id}>{l.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Hủy</Button>
                    <Button disabled={box ? false : true} onClick={handleExportReport}>Đồng ý</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default FunctionButtons;