import {
  Http
} from "../Services/http";
import {
  startOfDay,
  endOfDay
} from "date-fns";
import {
  ZONE_ID,
  BOXGROUP_ID
} from "../Utils/const";

const API_ENDPOINT = {
  LIST_BOX_GROUP: "zone/box_group/list?zone_id=",
  LIST_BOX: "zone/box/list?group_id=",
  RECORD_BY_BOX_GROUP: "sensor/group?id=",
  REPORT_BY_BOX_GROUP: "zone/report?box_group=",
  RECORD_BY_BOX: "sensor/box",
  GET_LIST_USER: "user/list",
  CREATE_USER: "user/create",
  UPDATE_USER: "user/update?id=",
  GET_USER_BY_ID: "user/get?id=",
  DELETE_USER: "user/delete?id=",
  SET_PASSWORD: "auth/user/set_password"

}
class SharedService {
  constructor() {
    if (SharedService._instance) {
      return SharedService._instance;
    }
    SharedService._instance = this;
  }

  listBox(group_id = BOXGROUP_ID) {
    return Http.get(API_ENDPOINT.LIST_BOX + group_id);
  }

  listBoxGroup(zone_id = ZONE_ID) {
    return Http.get(API_ENDPOINT.LIST_BOX_GROUP + zone_id);
  }

  getBoxGroup(id = BOXGROUP_ID) {
    return Http.get(API_ENDPOINT.GET_BOX_GROUP + id);
  }

  listRecordBoxGroup(metric, time, id = BOXGROUP_ID) {
    if (metric) {
      if (time) {
        const [min_ts, max_ts] = [
          startOfDay(new Date(time.start).getTime()).getTime(),
          endOfDay(new Date(time.end).getTime()).getTime()
        ]
        return Http.get(`${API_ENDPOINT.RECORD_BY_BOX_GROUP}${id}&metric=${metric}&min_ts=${min_ts}&max_ts=${max_ts}`);
      }
      return Http.get(`${API_ENDPOINT.RECORD_BY_BOX_GROUP}${id}&metric=${metric}`);
    }
    return Http.get(API_ENDPOINT.RECORD_BY_BOX_GROUP + id);
  }

  listRecordBoxGroupByTime(time, id = BOXGROUP_ID) {
    if (time) {
      const [min_ts, max_ts] = [
        startOfDay(new Date(time.start).getTime()).getTime(),
        endOfDay(new Date(time.end).getTime()).getTime()
      ]
      return Http.get(`${API_ENDPOINT.RECORD_BY_BOX_GROUP}${id}&min_ts=${min_ts}&max_ts=${max_ts}`);
    }
    return Http.get(API_ENDPOINT.RECORD_BY_BOX_GROUP + id);
  }

  listRecordByBox(box_id, filter) {
    const qs = {
      id: box_id,
      ...filter
    };
    return Http.get(API_ENDPOINT.RECORD_BY_BOX, qs);
  }

  reportByBoxGroup(id = BOXGROUP_ID, metric) {
    return Http.get(`${API_ENDPOINT.REPORT_BY_BOX_GROUP}${id}&metrics=${metric}`);
  }
  getListUser() {
    return Http.get(API_ENDPOINT.GET_LIST_USER);
  }
  createUser(data) {
    return Http.post(API_ENDPOINT.CREATE_USER, data)
  }
  updateUser(data, id) {
    return Http.post(`${API_ENDPOINT.UPDATE_USER}${id}`, data)
  }
  getUser(id) {
    return Http.get(`${API_ENDPOINT.GET_USER_BY_ID}${id}`)
  }
  deleteUser(id) {
    return Http.post(`${API_ENDPOINT.DELETE_USER}${id}`)
  }
  setPasswordUser(params) {
    return Http.post(`${API_ENDPOINT.SET_PASSWORD}`,params)
  }
}
const sharedService = new SharedService();

export default sharedService;