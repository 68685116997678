import { Children, useState, useEffect } from "react";
import { Paper, Skeleton, } from "@mui/material";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import MarkerMap from "./MarkerMap";
import LayerMap from "./LayerMap";
import { Marker, Popup } from "react-leaflet";
import sharedService from "../../../../../Services/SharedService";


const Map = (props) => {
    const { center, isLoading } = props;
    const [ boxRecords, setBoxRecords ] = useState([])
    const MyMap = () => {
        const map = useMap();
        map.flyTo(map.getCenter(), map.getZoom());
        return null;
    }

    function selectBox(e, boxId) {
        let result = props.group.boxs.find(b => b.id === boxId);
        sharedService.listRecordByBox(boxId).then(res => {
            if(res.data && res.data.count > 0) setBoxRecords(res.data.records)
        })
    }

    return (
        <>
            {/* <Grid container sx={{ height: "0.4vh" }} /> */}
            <Paper
                elevation={8}
                className="Map-Right"
                sx={{
                    boxShadow: 'none',
                    borderLeft: '1.5px solid',
                    borderTop: '1.5px solid',
                    borderBottom: '3px solid',
                    borderRight: 'none',
                    color: '#0000001f',
                    borderRadius: '5px',
                    marginTop: {xs: '20px', lg: '0px'}
                }}
            >
                <div>
                    {!isLoading ?

                        <MapContainer
                            center={center}
                            zoom={14}
                            maxZoom={18}
                            className="Map-Right-Container"
                        >
                            <LayerMap layers="default" />
                            <MyMap />
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {props.group?.boxs.map((box, index) => {
                                return <MarkerMap selectBox={selectBox} index={index} box={box} records={boxRecords} />
                            })}
                        </MapContainer> 
                        :
                        <>
                            {Children.toArray([...Array(3)].map((_, i) => {
                                return (
                                    <Skeleton
                                        animation="wave"
                                        className="mb-8 width-percent-93 ml-20"
                                        style={{ height: "12vh" }}
                                    />
                                )
                            }))}
                        </>}
                </div>
            </Paper>
        </>
    )
}

export default Map;