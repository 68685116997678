import { Children, useState, useEffect } from "react";
import { Paper, Typography, Grid, Tooltip } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { getMinutes } from "date-fns";
import sharedService from "../../../../../Services/SharedService";

// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import LineChart from "../../../../Components/Chart/Component/LineChart";
import BarChart from "../../../../Components/Chart/Component/BarChart";
import WaterLevelView from "../../../../Components/Chart/Component/WaterLevelView";
import MultiLineChart from "../../../../Components/Chart/Component/MultiLineChart";

import { calculateQS as CalculateFlowSpin } from "../../../../Components/Chart/Component/chart_data";
import { CalculateFlow as CalculateFlowDrain } from "../../../../Components/Interpolation/drain";
import image from "../../../../../Asset/water_proof2/daton1.png";
import { BuildChartData, adjustRAData, TYPE_CHART } from "../../../../Components/Chart/Component/chart_data";
import { ID_GROUPS } from "../../../../../Utils/const";

const Chart = (props) => {
    const [dataWaterLevelView, setDataWaterLevelView] = useState()
    const [charts, setCharts] = useState();
    const [slideIndex, setSlideIndex] = useState(+1);

    const checkKG = window.localStorage.getItem('group_id') === ID_GROUPS.KG

    useEffect(async () => {
        let z; // Ztl
        let dr; // độ mở cống
        let q; // lưu lượng
        const groupRecord = await (await sharedService.listRecordBoxGroup()).data;

        if(checkKG) {
            groupRecord.map(g => {
                g.records = g.records.filter(r => getMinutes(r.t * 1000) === 0 || getMinutes(r.t * 1000) === 30)
                return g
            });
        }
        // Thông số hiện tại
        groupRecord.forEach(g => {
            if(g.count === 0) return;
            if (g.box.id === 'WK34ZRT9') {
                const record = g.records[0]
                z = record?.WAU
                q = CalculateFlowSpin(record, z)
            }
            if (g.box.id === 'R8ZLNSJX') {
                dr = g.records[0]?.DR1 / 1000
            }
        })
        const dataWLV = {z, q, q_of: CalculateFlowDrain(dr, z) || 0}
        setDataWaterLevelView(dataWLV)
        //////
        const result = groupRecord.map(g => {
            if(g.count === 0) return
            const chart = BuildChartData(g.records, g.box, z)
            return chart
        })

        const docs = adjustRAData(result.filter(r => r))
        if(checkKG) setCharts(docs.filter(d => d.type))
        else setCharts(docs)

    }, [])

    const handleSlide = (e) => {
        const { activeIndex } = e;
        setSlideIndex(activeIndex + 1);
    }

    let isFullScreen = false;

    const handleFullScreen = (el) => {
        const img = el.target;
        isFullScreen = !isFullScreen;
        if (isFullScreen) {
            img.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    }

    return (
        <>
            <Paper
                className="Chart-Right"
                elevation={8}
                sx={{
                    boxShadow: 'none',
                    border: '1.5px solid',
                    borderRight: 'none',
                    color: ' #0000001f',
                    borderRadius: '5px'
                }}
            >
                <div className="middle top">
                    <Grid container>
                        <Grid item xs={12} className="middle">
                            <Typography
                                className="title"
                                sx={{ fontSize: {xs: '16px', lg: '18px'}}}
                            >
                                Dữ liệu biểu đồ
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={5} className="pl-40 pt-8 pr-40 end pointer">
                            <Tooltip title="Toàn màn hình">
                                <FullscreenIcon className="fontsz-30" onClick={handleFullScreen} />
                            </Tooltip>
                        </Grid> */}
                    </Grid>
                </div>
                <div className="content">
                    <Swiper
                        className="content-carousel"
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        spaceBetween={50}
                        slidesPerView={1}
                        // navigation
                        pagination={{ clickable: true }}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }}
                        // scrollbar={{ draggable: true }}
                        // onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={handleSlide}
                    >
                        {charts && Children.toArray(charts.map(t => {
                            if (t.metric === 'WAU') return <SwiperSlide onDoubleClick={handleFullScreen} className='pointer'><WaterLevelView  box_id={t.box_id} data={dataWaterLevelView} /></SwiperSlide>
                            if (t.type === 'line') return <SwiperSlide onDoubleClick={handleFullScreen} className='pointer'><LineChart max={checkKG && t.metric === 'WA' ? 60 : null} min={checkKG && t.metric === 'WA' ? 0 : ''} data={t} /></SwiperSlide>
                            if (t.type === 'multiLine') return <SwiperSlide onDoubleClick={handleFullScreen} className='pointer'><MultiLineChart data={t}  /></SwiperSlide>
                            if (t.type === 'bar') return <SwiperSlide onDoubleClick={handleFullScreen} className='pointer'><BarChart data={t} /></SwiperSlide>
                            else return <SwiperSlide onDoubleClick={handleFullScreen} className='pointer'><img src={image} className="width-percent-100" alt="" /></SwiperSlide>
                        }))}
                    </Swiper>
                </div>
                <div className="bot">
                    <Typography className="fontsz-14 end pr-17">
                        <>Hiển thị {slideIndex}/ <b>{charts?.length}</b> biểu đồ</>
                    </Typography>
                </div>
            </Paper>
        </>
    )
}

export default Chart;