import { NAMED_COLORS } from "../../App/Utils/CustomChart";
import { CalculateFlow, getGroupId } from "../../Interpolation/drain";
import { CalculateFlow as CalculateFlowSpin } from "../../Interpolation/spin";
import { SortChart } from "./sort_chart";

export const TYPE_CHART = ["Bar", "Water", "Line", "MultiLine", ""]

// Cao trình đáy tràn sông lũy
const ZD = 120.5;

export const METRIC_GROUPS = [
    // { label: 'mực nước', metric: 'WA', unit: 'm', chart_type: 'dualVolume' },
    { label: 'mực nước', metric: 'WA', unit: 'm' },
    { label: 'độ mở cống', metric: 'DR', unit: 'm', chart_type: 'dualQ', type: 'multiLine' },
    { label: 'đo thấm', metric: 'WP', unit: 'mH2O' },
    { label: 'độ mặn', metric: 'SA', unit: 'S‰' },
    { label: 'lượng mưa', metric: 'RA', unit: 'mm', chart_type: 'dualRA', type: 'bar' },
    { label: 'mực nước ngầm', metric: 'PZ', unit: 'mH2O' },
    { label: 'đo dịch động', metric: 'ICI', unit: 'cm' },
    { label: 'đo nghiêng', metric: 'MEI', unit: 'cm' },
];

function addFullService(sensors) {
    if (sensors.some(m => (m.code === 'WAU'))) {
        // if (sensors.some(m => (m.code === 'V'))) {
        //     return sensors
        // }
        // const volume = {
        //     code: 'V',
        //     name: 'Thể tích',
        //     metric: 'WA'
        // }
        // sensors.push(volume)
        if (sensors.some(m => m.metric === 'DR')) {
            // Q Spin: lưu lượng tràn 
            const QS = {
                code: 'QS',
                name: 'Lưu lượng qua tràn',
                metric: 'DR'
            }
            sensors.push(QS)
        }
    }
    return sensors
}
function buildTimeSeries(records, sensors = []) {
    sensors = addFullService(sensors)
    const timeseries = {};
    for (const r of records) {
        const date = r.t * 1000;
        for (const m of sensors) {
            const code = m.code;
            const value = r[code];
            if (isNaN(value)) {
                continue;
            }
            if (!timeseries[code]) {
                timeseries[code] = {
                    min: null,
                    max: null,
                    records: []
                };
            }
            if (!timeseries[code].min) timeseries[code].min = value;
            if (!timeseries[code].max) timeseries[code].max = value;
            if (timeseries[code].min > value) timeseries[code].min = value;
            if (timeseries[code].max < value) timeseries[code].max = value;
            timeseries[code].records.push([date, value]);
        }
    };
    return timeseries;
}

export function calculateQS(record, z) {
    const { DR1, DR2, DR3 } = record;
    let number_of_drain = 3;
    let dr_value = 0;
    if (DR1 > 0 && DR2 > 0 && DR3 > 0) {
        if (DR1 === DR2 && DR1 === DR3) dr_value = DR1
        else dr_value = (DR1 + DR2 + DR3) / 3
    } else {
        if (DR1 === 0) {
            if(DR2 === 0) {
                number_of_drain = 1
                dr_value = DR3
            }else if(DR3 === 0) {
                number_of_drain = 1
                dr_value = DR2
            }else {
                if(DR2 === DR3) {
                    number_of_drain = 2
                    dr_value = DR2
                }else {
                    number_of_drain = 2
                    dr_value = (DR2 + DR3) / 2;
                }
            }
        } else if (DR2 === 0) {
            if(DR3 === 0) {
                number_of_drain = 1
                dr_value = DR1
            }else {
                if(DR1 === DR3) {
                    number_of_drain = 2;
                    dr_value = DR1
                }else {
                    number_of_drain = 2;
                    dr_value = (DR1 + DR3) / 2;
                }
            }
        }  else if (DR3 === 0) {
            number_of_drain = 2
            if(DR1 === DR2) dr_value = DR1
            else dr_value = (DR1 + DR2) /2
        }
    }
    const result = CalculateFlowSpin(number_of_drain, z, dr_value);
    return result
}

function buildRecordQS(records, z) {
    records.map(r => {
        if (r.WAU) {
            const result = calculateQS(r, z)
            r.QS = result
        }
        return r;
    })
}

export function BuildChartData(records, box, z) {
    buildRecordQS(records, z)
    const timeseries = buildTimeSeries(records, box.metrics);
    const charts = [];
    METRIC_GROUPS.forEach(mg => {
        const { type = 'line', metric } = mg;
        let labels;
        const datasets = [];
        box.metrics.filter(m => m.metric === metric)
            .forEach((m, i) => {
                const series = timeseries[m.code]?.records.reverse() || [];
                const label = m.name;
                const data = series.map(t => {
                    // if(m.code === 'Q') return Math.floor(Math.random() * 100)
                    if (metric === 'DR' && m.code !== 'QS') return Math.round(t[1] / 1000 * 100) / 100;
                    return t[1];
                });
                if (!labels) labels = series.map(t => t[0]);
                const color = NAMED_COLORS[i];
                if (metric === 'DR' && box.id === 'R8ZLNSJX') {
                    const currentData = series.map(s => {
                        const value = +s[1] / 1000;
                        const result = CalculateFlow(value, +z);
                        return result
                    })
                    datasets.push({ label, data, borderColor: color, backgroundColor: color, code: m.code })
                    datasets.push({ label: 'Lưu lượng qua cống', data: currentData, borderColor: NAMED_COLORS[i + 1], backgroundColor: NAMED_COLORS[i + 1], yAxisID: 'y1', box: box.name, code: m.code })
                } else if (m.code === 'QS') {
                    datasets.push({ label, data, borderColor: color, backgroundColor: color, yAxisID: 'y1', code: m.code })
                } else if (m.code === 'RA') {
                    const pos = box.name.search('-')
                    if(pos < 0) {
                        const text = box.name.charAt(0).toUpperCase() + box.name.slice(1)
                        datasets.push({ label: text, data, borderColor: color, backgroundColor: color, code: m.code })
                    }else {
                        const str = box.name.slice(pos + 2);
                        const text = str.charAt(0).toUpperCase() + str.slice(1);
                        datasets.push({ label: text, data, borderColor: color, backgroundColor: color, code: m.code })
                    }
                }
                else {
                    datasets.push({ label, data, borderColor: color, backgroundColor: color, code: m.code })
                }
            });

        if (datasets.length < 1) {
            return;
        }
        if (metric === 'WA' && datasets.some(d => d.code === 'WAU')) charts.push({ type: '', datasets: [], labels, metric: 'WAU', box_id: box.id })
        charts.push({ type, datasets, labels, metric, box_id: box.id })
    });
    return charts
}

export function adjustRAData(input) {
    let firstRA;
    const flat = input.flat()
    // Sắp xếp biểu đồ
    const isSort = SortChart.find(s => s.group_id === getGroupId())
    if(isSort) {
        const order = isSort.order
        flat.sort((a, b )=> {
            if(b.metric === 'WAU') return 1
            else {
                const before = order.findIndex(o => o === a.datasets[0]?.code)
                const after = order.findIndex(o => o === b.datasets[0]?.code)
                return before -after
            }
        })
    }
    flat.forEach((r, i) => {
        if (r.metric === 'RA') {
            if (firstRA > -1) r.datasets.forEach(d => {
                const color = NAMED_COLORS[flat[firstRA].datasets.length];
                d.borderColor = color;
                d.backgroundColor = color;
                flat[firstRA].datasets.push(d);
            });
            else firstRA = i;
        }
    })
    const docs = flat.filter((r, i) => {
        if (r.metric === 'RA') {
            if (i === firstRA) return r
            else return null
        }
        else return r
    })
    return docs
}