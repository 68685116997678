import { useState, useEffect } from "react";
import { Switch, Route } from "react-router";
import ListData from "./Components/ListData";
import Report from "./Components/Report";
import sharedService from "../../../Services/SharedService";
import { CHECK_EMPTY_STRING } from "../../../Utils/string";
import ComputeInterpolation from "./Components/Compute";
import { ID_GROUPS } from "../../../Utils/const";
import DataDOT from "./Components/ListDataDOT";
import ListDataKG from "./Components/ListDataKG";

const Monitor = (props) => {
    let { groups, groupId, time, exportExcel, handleExportExcel } = props;
    // const [records, setRecords] = useState([]);
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    if (CHECK_EMPTY_STRING(groupId)) {
        groupId = groups.length !== +0 ? groups[0].id : "";
    }
    const id = window.localStorage.getItem('group_id')
    useEffect(() => {
        setIsLoading(true);
        sharedService.reportByBoxGroup(id, 'WAU').then(
            res => {
                const docs = res.data;
                const report = docs.map(obj => {
                    const { count, info, total } = obj;
                    return {
                        metric: info.metric,
                        date: info.month >= 10 ? `${info.month}/${info.year}` : `0${info.month}/${info.year}`,
                        total,
                        count
                    }
                })
                report.sort((a, b) => {
                    const [monthA, yearA] = a.date.split("/");
                    const [monthB, yearB] = b.date.split("/");
                    return new Date(+yearB, +monthB, 1).getTime() - new Date(+yearA, +monthA, 1).getTime();
                });
                setReports(report);
                setIsLoading(false);
                sharedService.reportByBoxGroup(id, 'RA').then(
                    res => {
                        const docs = res.data;
                        const reportRA = docs.map(obj => {
                            const { count, info, total } = obj;
                            return {
                                metric: info.metric,
                                date: info.month >= 10 ? `${info.month}/${info.year}` : `0${info.month}/${info.year}`,
                                total,
                                count
                            }
                        })
                        report.sort((a, b) => {
                            const [monthA, yearA] = a.date.split("/");
                            const [monthB, yearB] = b.date.split("/");
                            return new Date(+yearB, +monthB, 1).getTime() - new Date(+yearA, +monthA, 1).getTime();
                        });
                        setReports([...report, ...reportRA]);
                        setIsLoading(false);
                    }
                ).catch(err => console.log(err))
            }
        ).catch(err => console.log(err))

    }, [groupId])

    return (
        <div>
            <Switch>
                <Route path="/app/monitor/list"
                    render={() => {
                        if(id === ID_GROUPS.DOT) return <DataDOT exportExcel={exportExcel} handleExportExcel={handleExportExcel} group_id={id} time={time} boxs={groups} boxSelected={props.boxSelected}/>
                        else if(id === ID_GROUPS.KG) return <ListDataKG  exportExcel={exportExcel} handleExportExcel={handleExportExcel} group_id={id} time={time} boxs={groups} boxSelected={props.boxSelected} />
                        else return <ListData group_id={id} listBox={groups} time={time} setIsLoading={setIsLoading} />
                    }}
                />
                <Route path="/app/monitor/report"
                    render={() => <Report group_id={id} data={reports} isLoading={isLoading} />}
                />
                <Route path="/app/monitor/compute"
                    render={() => <ComputeInterpolation />}
                />
            </Switch>
        </div>
    )
}

export default Monitor;