import { Children, useEffect, useState } from 'react';
import { format } from 'date-fns';
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CustomPagination from '../../../Components/Pagination/Pagination';
import FunctionButtons from '../../../Components/FunctionButtons/FunctionButtons';
import CustomLoading from '../../../Components/Loading/CustomLoading';
import ModalInput from '../Shared/ModalInput/ModalInput';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import sharedService from './../../../../Services/SharedService';
import Ztl from './Ztl';
import Zhl from './Zhl';
import QxaCong from './QxaCong';
import { BOXGROUP_ID } from '../../../../Utils/const'
import MenuApp from '../../../Components/MenuApp/MenuApp';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function ConvertBoxName(string) {
    if (string.includes("-")) {
        const array = string.split("- Trạm đo mưa");
        return array.length >= 2 ? array[1] : array[0];
    }
    return string;
}

const ListData = (props) => {
    const { time, listBox, group_id } = props;
    const [page, setPage] = useState(+0);
    const [rowsPerPage, setRowsPerPage] = useState(+15);
    const [isOpen, setIsOpen] = useState(false);
    const [recordsData, setRecordsData] = useState([]);

    let records = [];
    let headers = [];

    if (recordsData && recordsData.length !== +0) {
        if(BOXGROUP_ID === 'IXC8QY8W') {
            const newData = JSON.parse(JSON.stringify(recordsData));
            newData.sort((a, b) => b.count - a.count);
            const date = newData[0].records.map(r => r.t);
            let rainDuringDay = 0;
            records = date.reverse().map((t,i) => {
                const labelTime = format(t * 10e2, "dd/MM/yyyy - hh:mmaaaaa'm'")
                let obj = recordsData.reduce((result, dt) => {
                    const r = dt.records.find(r => r.t === t);
                    return r ? { ...result, [ConvertBoxName(dt.box.name)]: r.RA} : { ...result, [dt.box.name]: +0 }
                }, { "Thời gian": labelTime });
                const arrRA = Object.values(obj);
                // if(labelTime.slice(13) === '08:00am') rainDuringDay = 0;
                if (new Date(t * 10e2).getHours() === +8) rainDuringDay = +0;
                rainDuringDay += arrRA[1]
                obj["Lượng mưa trong ngày"] = Math.round(rainDuringDay * 100) / 100;
                return obj;
            })
            records.reverse();
            if(records.length > 0) headers = Object.keys(records[0]);
        } else {
            const newData = JSON.parse(JSON.stringify(recordsData));
            newData.sort((a, b) => b.count - a.count);
            const date = newData[0].records.map(r => r.t);
            records = date.map(t => {
                let obj = recordsData.reduce((result, dt) => {
                    const r = dt.records.find(r => r.t === t);
                    return r ? { ...result, [ConvertBoxName(dt.box.name)]: r.RA} : { ...result, [dt.box.name]: +0 }
                }, { "Thời gian": format(t * 10e2, "dd/MM/yyyy - HH:mm") });
                let arrRA = Object.values(obj);
                arrRA.shift();
                obj = {...obj, "TỔNG": arrRA.reduce((pre, cur) => pre + cur,+0).toFixed(1)}
                return obj;
            })
            if(records.length > 0) headers = Object.keys(records[0]);
        }
    }

    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
    };

    const handleOpenModal = (isOpen, file) => {
        if (file) {
            console.log("done", file);
        }
        setIsOpen(isOpen);
    }
    const [value, setValue] = useState(0);

    //get data for Ztl
    const [dataZtl, setDataZtl] = useState()
    const [isLoadingZ, setIsLoadingZ ] = useState(true)

    //data for Zhl
    const [dataZhl, setDataZhl] = useState()

    //data Qxa
    const [dataQxa, setDataQxa] = useState([])
    const [wauArr, setWauArr] = useState([])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // for menu app
    const domain = window.location.pathname;

    const [timeChange , setTimeChange] = useState({
        start: format(Date.now(), "yyyy-MM-dd"),
        end: format(Date.now(), "yyyy-MM-dd")
    })
    const handleChangeBoxGroup = (group_id, time) => {
        if (typeof time === "object") {
            setTimeChange(time)
        }
    }
    useEffect(() => {
        if(time !== timeChange){
            setTimeChange(time)
        }
    }, [time])
    useEffect(() => {
        sharedService.listRecordBoxGroup("RA", timeChange).then(
            res => {
                const docs = res.data;
                setRecordsData(docs);
            }
        ).catch(err => console.log(err));
    }, [])
    useEffect(() => {
        setIsLoadingZ(true)
        if(+value === 0){
            sharedService.listRecordBoxGroup("RA", timeChange).then(
                res => {
                    const docs = res.data;
                    setRecordsData(docs);
                    setIsLoadingZ(false)
                }
            ).catch(err => console.log(err));
        }
        if(+value === 1) {
        sharedService.listRecordBoxGroup("WAU", timeChange).then(
                res => {
                    const docs = res.data;
                    setDataZtl(docs);
                    setIsLoadingZ(false)
                }
            ).catch(err => console.log(err));
        }
        if(+value === 2) {
            sharedService.listRecordBoxGroup("WAD", timeChange).then(
                res => {
                    const docs = res.data;
                    setDataZhl(docs);
                    setIsLoadingZ(false)
                }
            ).catch(err => console.log(err));
        }
        if(+value === 3) {
            sharedService.listRecordBoxGroupByTime(timeChange).then(
                res => {
                    const arrData = res?.data.filter(d => d.metrics.includes("DR1"))

                    const arr = res?.data.filter(d => d.metrics.includes("WAU")).map(d => {
                        const result = d.records.map(r => {
                            const obj = { t: r.t, WAU: r.WAU }
                            return obj
                        })
                        setWauArr(result)
                    })
                    setDataQxa(arrData)
                    setIsLoadingZ(false)
                }
            ).catch(err => console.log(err));
        }
    }, [value, timeChange])
    

    return (
        <>
            <Paper className="paper-listData" sx={{ overflow: 'hidden' }} elevation={8}>
            <Grid container columns={12} sx={{
                display: { xs: 'block', md: 'none'},
                marginTop: {xs: "20px", md: '0px' }
            }} >
                <MenuApp
                    time={timeChange}
                    domain={domain}
                    parentCallBack={handleChangeBoxGroup}
                />
            </Grid>
                <Grid itemn xs={12} sx={{ margin: "3%", display: {xs: 'block', sm: 'none'} }} className="end">
                        <FunctionButtons listBox={listBox} time={time} parentCallBack={handleOpenModal} />
                </Grid>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Lượng mưa" {...a11yProps(0)} />
                        <Tab label="ZTL" {...a11yProps(1)} />
                        <Tab label="ZHL" {...a11yProps(2)} />
                        {
                            group_id === "KKKXW2TW" ?
                                <Tab label="Qxả" {...a11yProps(3)} /> 
                            : 
                            null
                        }
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Grid item xs={12} sx={{ pt: "0.5%",  display: {xs: 'none', sm: 'block'}}} className="end function-buttons">
                        <FunctionButtons listBox={listBox} time={time} parentCallBack={handleOpenModal} />
                    </Grid>
                    <TableContainer className="table-listdata" >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {headers.length !== +0 && Children.toArray(headers.map((h, i) => {
                                        return i === +0 ?
                                            <>
                                                <TableCell key={i} align='left' style={{ width: 100 }}>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: "bold",
                                                            textTransform: 'uppercase',
                                                            fontSize: 13,
                                                            color: "#606060",
                                                        }}
                                                    >
                                                        {h}
                                                    </Typography>
                                                </TableCell>
                                            </>
                                            : <>
                                                <TableCell key={i} align='right' style={{ width: Math.ceil(100 / headers.length) }}>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: "bold",
                                                            textTransform: 'uppercase',
                                                            fontSize: 13,
                                                            color: "#606060",
                                                        }}
                                                    >
                                                        {h}
                                                    </Typography>
                                                </TableCell>
                                            </>
                                    }))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!isLoadingZ ? records.length === +0 ? <>
                                    <TableRow> <TableCell colSpan={6}>Không có dữ liệu</TableCell> </TableRow> 
                                </> : Children.toArray(records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((dt, i) => {
                                    return (
                                        <TableRow
                                            key={i}
                                            hover
                                            role="checkbox"
                                        >
                                            {Object.values(dt).map((v, i) => {
                                                return i === +0 ?
                                                    <>
                                                        <TableCell  key={i} align='left' component="th" scope="row" style={{ width: "18%" }}>
                                                            <Typography
                                                                sx={{
                                                                    textTransform: 'uppercase',
                                                                    fontWeight: 600,
                                                                    fontSize: 16,
                                                                    color: "#444444",
                                                                }}
                                                            >
                                                                {v}
                                                            </Typography>
                                                        </TableCell>
                                                    </> : <>
                                                        <TableCell  key={i} align="right">
                                                            <Typography
                                                                sx={{
                                                                    textTransform: 'uppercase',
                                                                    fontWeight: 600,
                                                                    fontSize: 16,
                                                                    color: "#444444"
                                                                }}
                                                            >
                                                                {v}
                                                            </Typography>
                                                        </TableCell>
                                                    </>
                                            })}
                                        </TableRow>
                                    )
                                })) : <CustomLoading numbers={7} />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container sx={{ p: "1%", ml: "1%" }}>
                        <CustomPagination
                            data={records}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            parentCallBack={handleChangePage}
                        />
                    </Grid>
                    <ModalInput isOpen={isOpen} parentCallBack={handleOpenModal} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Grid item xs={12} sx={{ pt: "0.5%",  display: {xs: 'none', sm: 'block'}}} className="end function-buttons">
                        <FunctionButtons listBox={listBox} time={time} parentCallBack={handleOpenModal} />
                    </Grid>
                    <Ztl dataZtl={dataZtl} isLoading={isLoadingZ} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Grid item xs={12} sx={{ pt: "0.5%",  display: {xs: 'none', sm: 'block'}}} className="end function-buttons">
                        <FunctionButtons listBox={listBox} time={time} parentCallBack={handleOpenModal} />
                    </Grid>
                    <Zhl dataZhl={dataZhl} isLoading={isLoadingZ} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Grid item xs={12} sx={{ pt: "0.5%",  display: {xs: 'none', sm: 'block'}}} className="end function-buttons">
                        <FunctionButtons listBox={listBox} time={time} parentCallBack={handleOpenModal} />
                    </Grid>
                    <QxaCong data={dataQxa} isLoading={isLoadingZ}/>
                </TabPanel>
            </Paper>
        </>
    )
}

export default ListData;