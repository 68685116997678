export const openNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null;
}

export const goTo = (url = "") => {
    url = window.location.origin + "/" + url;
    window.location.replace(url);
}

export const ResetForm = (state) => {
    const data_type = typeof state;
    if (data_type === "object") {
        const new_state = Object.entries(state).reduce((r, s) => {
            const [key, value] = s;
            const type = typeof value;
            if (type === "number") return { ...r, ...{ [key]: +0 } };
            if (type === "boolean") return { ...r, ...{ [key]: !value } }
            if (type === "string") return { ...r, ...{ [key]: "" } };
            if (type === "object") {
                if (Array.isArray(value)) {
                    return { ...r, ...{ [key]: Object.values(ResetForm(value)) } };
                }
                return { ...r, ...{ [key]: ResetForm(value) } };
            }
            return r;
        }, {})
        return new_state;
    }
    return;
}

export const ConvertCSVToJSON = (csv) => {
    const lineArray = csv.split("\n");
    const strReplace = ",";
    const lines = lineArray.map(l => {
        const row = l.replace(/[\s]+[,]+|[,]+[\s]+/g, strReplace).trim();
        return row;
    })
    lines.splice(lines.length - 1, 1);
    const headers = lines[0].split(strReplace);
    const result = lines.map(l => {
        const obj = {};
        const currentLine = l.split(strReplace);
        headers.forEach((h, i) => {
            obj[h] = + currentLine[i]
        })
        return obj;
    });

    //remove lines header
    result.splice(0, 1)
    return result;
}