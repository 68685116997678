import React from "react";
import { Marker } from "react-leaflet";
import { iconPlace, iconWA, iconSA, iconRA, iconWP, iconDR, iconRadio, iconAudio, iconICI, iconMEI } from "./icon";
import { TYPE_BOX_MAP } from "../../../../../Utils/const";
import PopupBox from "./BoxPopup";
import SensorPopup from "./SensorPopup";

const MarkerMap = (props) => {
    function getIcon(type) {
        switch (type) {
            case TYPE_BOX_MAP.WA.code:
                return iconWA
            case TYPE_BOX_MAP.WP.code:
                return iconWP
            case TYPE_BOX_MAP.RA.code:
                return iconRA
            case TYPE_BOX_MAP.DR.code:
                return iconDR
            case TYPE_BOX_MAP.SA.code:
                return iconSA
            case TYPE_BOX_MAP.AUDIO.code:
                return iconAudio
            case TYPE_BOX_MAP.ICI.code:
                return iconICI
            case TYPE_BOX_MAP.MEI.code:
                return iconMEI
            case TYPE_BOX_MAP.PZ.code:
                return iconWA
            default:
                return iconPlace
        }
    }

    return <>
        {props.box.type !== TYPE_BOX_MAP.AUDIO.code &&
            <Marker key={props.index} position={[props.box.location.lat, props.box.location.lng]}
                icon={getIcon(props.box.type)}
                eventHandlers={{
                    click: e => props.selectBox(e, props.box.id),
                }}>
                <PopupBox box={props.box} record={props.records[0]} />
            </Marker>
        }
        {props.box.metrics.map((metric, i) => {
            if (metric.lat && metric.lng) {
                return <Marker key={i} position={[metric.lat, metric.lng]}
                    icon={getIcon(metric.metric)}>
                    <SensorPopup box={props.box} metric={metric} record={props.record} />
                </Marker>
            } else return null
        })}
    </>
};

export default MarkerMap;
