import { useState, useEffect, Children } from "react";
import { Grid } from "@mui/material";
import Statistical from "./Statistical/Statistical";
import sharedService from "../../../../Services/SharedService";
import Map from "./Map/Map";
import { BuildChartData, adjustRAData, METRIC_GROUPS } from "../../../Components/Chart/Component/chart_data";
import LineChart from "../../../Components/Chart/Component/LineChart";
import BarChart from "../../../Components/Chart/Component/BarChart";

const DashBoardDOT = ({ records, group, center, isLoading }) => {
    const [charts, setCharts] = useState();
    
    useEffect(async () => {
        const groupRecord = await (await sharedService.listRecordBoxGroup()).data;
        const result = groupRecord.map(g => {
            if (g.count === 0) return
            const chart = BuildChartData(g.records, g.box)
            return chart
        })
        const docs = adjustRAData(result.filter(r => r))
        setCharts(docs)
    }, [])

    return (
        <>
            <Grid className="DashBoardDOT" container columns={12}>
                <Grid item xs={12} lg={6} sx={{ marginTop: { xs: '20px', lg: '0px' } }} className="list-chart">
                    {charts && Children.toArray(charts.map(t => {
                        if (t.type === 'line') return <div className="chart-container">
                            <p>Biểu đồ dữ liệu {METRIC_GROUPS.find(m => m.metric === t.metric).label}</p>
                            <div className="chart-item">
                                <LineChart height='100%' data={t} />
                            </div>
                        </div>
                        if (t.type === 'bar') return <div className="chart-container">
                            <p>Biểu đồ dữ liệu {METRIC_GROUPS.find(m => m.metric === t.metric).label}</p>
                            <div className="chart-item">
                                <BarChart height='100%' data={t} />
                            </div>
                        </div>
                    }))}
                </Grid>
                <Grid item xs={0} lg={0.2}>
                </Grid>
                <Grid item xs={12} lg={5.5} sx={{ maxWidth: '1000px', minWidth: '260px', width: '200px', marginTop: { xs: '20px', lg: '0px' } }}>
                    <Statistical data={records} />
                    <Map group={group} center={center} isLoading={isLoading} />
                </Grid>
            </Grid>
        </>
    )
}

export default DashBoardDOT;