export const InterpolationSource = [
    // Sông Lũy
    {
        group_id: "KKKXW2TW",
        V: {
            "110": [0.00, 0.01, 0.02, 0.02, 0.03, 0.04, 0.05, 0.05, 0.06, 0.07],
            "111": [0.08, 0.09, 0.09, 0.10, 0.11, 0.12, 0.12, 0.13, 0.14, 0.15],
            "112": [0.16, 0.16, 0.17, 0.18, 0.19, 0.19, 0.20, 0.21, 0.22, 0.22],
            "113": [0.23, 0.24, 0.25, 0.26, 0.26, 0.27, 0.28, 0.29, 0.29, 0.30],
            "114": [0.31, 0.34, 0.36, 0.39, 0.41, 0.44, 0.46, 0.49, 0.51, 0.54],
            "115": [0.56, 0.63, 0.70, 0.77, 0.84, 0.91, 0.97, 1.04, 1.11, 1.18],
            "116": [1.25, 1.37, 1.48, 1.60, 1.72, 1.84, 1.95, 2.07, 2.19, 2.30],
            "117": [2.42, 2.59, 2.76, 2.92, 3.09, 3.26, 3.43, 3.60, 3.76, 3.93],
            "118": [4.10, 4.32, 4.55, 4.77, 5.00, 5.22, 5.44, 5.67, 5.89, 6.12],
            "119": [6.34, 6.67, 7.01, 7.34, 7.68, 8.01, 8.34, 8.68, 9.01, 9.35],
            "120": [9.68, 10.14, 10.59, 11.05, 11.50, 11.96, 12.42, 12.87, 13.33, 13.78],
            "121": [14.24, 14.79, 15.34, 15.89, 16.44, 16.99, 17.54, 18.09, 18.64, 19.19],
            "122": [19.74, 20.39, 21.04, 21.69, 22.34, 22.99, 23.63, 24.28, 24.93, 25.58],
            "123": [26.23, 26.99, 27.76, 28.52, 29.29, 30.05, 30.81, 31.58, 32.34, 33.11],
            "124": [33.87, 34.78, 35.68, 36.59, 37.49, 38.40, 39.31, 40.21, 41.12, 42.02],
            "125": [42.93, 43.98, 45.04, 46.09, 47.15, 48.20, 49.25, 50.31, 51.36, 52.42],
            "126": [53.47, 54.65, 55.84, 57.02, 58.20, 59.39, 60.57, 61.75, 62.93, 64.12],
            "127": [65.30, 66.60, 67.90, 69.20, 70.50, 71.80, 73.10, 74.40, 75.70, 77.00],
            "128": [78.30, 79.71, 81.12, 82.53, 83.94, 85.35, 86.76, 88.17, 89.58, 90.99],
            "129": [92.40, 93.91, 95.41, 96.92, 98.42, 99.93, 101.43, 102.94, 104.44, 105.95],
            "130": [107.45, 109.06, 110.67, 112.28, 113.89, 115.50, 117.11, 118.72, 120.33, 121.94],
            "131": [123.55, 125.27, 127.00, 128.72, 130.44, 132.17, 133.89, 135.61, 137.33, 139.06],
            "132": [140.78, 142.62, 144.47, 146.31, 148.16, 150.00, 151.84, 153.69, 155.53, 157.38],
            "133": [159.22, 161.18, 163.15, 165.11, 167.08, 169.04, 171.00, 172.97, 174.93, 176.90],
            "134": [178.86, 180.95, 183.04, 185.13, 187.22, 189.31, 191.39, 193.48, 195.57, 197.66],
            "135": [199.75, 201.96, 204.16, 206.37, 208.58, 210.79, 212.99, 215.20, 217.41, 219.61],
            "136": [221.82, 224.19, 226.57, 228.94, 231.32, 233.69, 236.06, 238.44, 240.81, 243.19],
            "137": [245.56, 248.05, 250.54, 253.04, 255.53, 258.02, 260.51, 263.00, 265.50, 267.99],
            "138": [270.48, 270.48, 270.48, 270.48, 270.48, 270.48, 270.48, 270.48, 270.48, 270.48]
        },
        D: {
            z: [118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132],
            dr: [0, 0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.2, 3.4, 3.6, 3.8, 4, 4.2, 4.4],
            value: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [5.3, 5.8, 6.2, 6.7, 7.1, 7.5, 7.8, 8.2, 8.5, 8.9, 9.2, 9.5, 9.8, 10, 10.3],
                [10.4, 11.4, 12.4, 13.3, 14.1, 14.9, 15.6, 16.3, 17, 17.6, 18.3, 18.9, 19.5, 20, 20.6],
                [15.4, 17, 18.4, 19.8, 21, 22.2, 23.3, 24.4, 25.4, 26.4, 27.3, 28.2, 29.1, 30, 30.8],
                [20.4, 22.5, 24.4, 26.2, 27.9, 29.4, 30.9, 32.3, 33.7, 35, 36.3, 37.5, 38.7, 39.8, 41],
                [25.2, 27.8, 30.3, 32.5, 34.6, 36.6, 38.5, 40.3, 42, 43.6, 45.2, 46.7, 48.2, 49.7, 51.1],
                [29.9, 33.1, 36, 38.8, 41.3, 43.7, 45.9, 48.1, 50.2, 52.2, 54.1, 55.9, 57.7, 59.4, 61.1],
                [34.5, 38.3, 41.7, 44.9, 47.9, 50.7, 53.3, 55.9, 58.3, 60.6, 62.9, 65, 67.1, 69.1, 71.1],
                [38.9, 43.3, 47.3, 51, 54.4, 57.6, 60.7, 63.6, 66.3, 69, 71.6, 74.1, 76.5, 78.8, 81],
                [43.3, 48.3, 52.8, 56.9, 60.8, 64.5, 67.9, 71.2, 74.3, 77.3, 80.2, 83, 85.7, 88.4, 90.9],
                [47.5, 53.1, 58.2, 62.8, 67.2, 71.2, 75.1, 78.8, 82.3, 85.6, 88.8, 92, 95, 97.9, 100.7],
                [51.6, 57.8, 63.4, 68.6, 73.4, 77.9, 82.2, 86.2, 90.1, 93.8, 97.4, 100.8, 104.2, 107.4, 110.5],
                [55.5, 62.4, 68.6, 74.3, 79.6, 84.5, 89.2, 93.6, 97.9, 101.9, 105.8, 109.6, 113.3, 116.8, 120.2],
                [59.4, 66.9, 73.7, 79.9, 85.7, 91, 96.1, 101, 105.6, 110, 114.3, 118.4, 122.3, 126.1, 129.9],
                [63.1, 71.3, 78.7, 85.4, 91.6, 97.5, 103, 108.2, 113.2, 118, 122.6, 127, 131.3, 135.4, 139.5],
                [66.6, 75.6, 83.5, 90.8, 97.5, 103.8, 109.8, 115.4, 120.8, 125.9, 130.9, 135.6, 140.2, 144.7, 149],
                [70.1, 79.7, 88.3, 96.1, 103.3, 110.1, 116.5, 122.5, 128.3, 133.8, 139.1, 144.2, 149.1, 153.9, 158.5],
                [73.3, 83.7, 92.9, 101.3, 109.1, 116.3, 123.1, 129.5, 135.7, 141.6, 147.2, 152.6, 157.9, 163, 167.9],
                [76.5, 87.6, 97.5, 106.4, 114.7, 122.4, 129.6, 136.5, 143, 149.3, 155.3, 161.1, 166.6, 172, 177.3],
                [79.4, 91.4, 101.9, 111.4, 120.2, 128.4, 136.1, 143.4, 150.3, 156.9, 163.3, 169.4, 175.3, 181, 186.6],
                [82.3, 95, 106.2, 116.3, 125.6, 134.3, 142.5, 150.2, 157.5, 164.5, 171.2, 177.7, 183.9, 190, 195.8],
                [84.9, 98.5, 110.4, 121.1, 131, 140.2, 148.8, 156.9, 164.6, 172, 179.1, 185.9, 192.5, 198.8, 205],
                [87.4, 101.8, 114.4, 125.8, 136.2, 145.9, 155, 163.5, 171.7, 179.4, 186.9, 194.1, 201, 207.6, 214.1]
            ]
        },
        S: {
            z: [121, 121.5, 122, 122.5, 123, 123.5, 124, 124.5, 125, 125.5, 126, 126.5, 127, 127.5, 128, 128.5, 129, 129.5, 130, 130.5, 131, 131.5],
            dr: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5],
            value1: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [5, 9, 12, 14, 16, 18, 19, 21, 22, 23, 24, 25, 27, 28, 29, 30, 31, 31, 32, 33, 34, 35],
                [5, 15, 21, 26, 30, 34, 37, 40, 43, 45, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 67, 69],
                [5, 16, 28, 36, 42, 48, 53, 58, 62, 66, 70, 73, 76, 80, 83, 86, 89, 92, 94, 97, 100, 102],
                [5, 16, 30, 43, 52, 60, 67, 74, 80, 85, 90, 95, 100, 104, 108, 113, 116, 120, 124, 128, 131, 134],
                [5, 16, 37, 46, 59, 70, 80, 88, 96, 103, 110, 116, 122, 127, 133, 138, 143, 148, 153, 157, 162, 166],
                [5, 16, 37, 46, 64, 78, 90, 101, 110, 119, 128, 135, 143, 150, 156, 163, 169, 175, 180, 186, 191, 197],
                [5, 16, 37, 46, 64, 83, 98, 112, 123, 134, 144, 153, 162, 171, 178, 186, 193, 200, 207, 214, 220, 226],
                [5, 16, 37, 46, 64, 85, 104, 120, 134, 147, 159, 170, 180, 190, 199, 208, 217, 225, 233, 241, 248, 255],
                [5, 16, 37, 46, 64, 85, 107, 127, 144, 159, 173, 185, 197, 209, 219, 229, 239, 249, 258, 266, 275, 283],
                [5, 16, 37, 46, 64, 85, 107, 130, 150, 168, 184, 199, 213, 226, 238, 249, 260, 271, 281, 291, 301, 310],
                [5, 16, 37, 46, 64, 85, 107, 131, 155, 175, 194, 211, 226, 241, 255, 268, 280, 292, 304, 315, 326, 336],
                [5, 16, 37, 46, 64, 85, 107, 131, 156, 180, 202, 221, 239, 255, 271, 285, 299, 313, 325, 338, 349, 361],
                [5, 16, 37, 46, 64, 85, 107, 131, 139, 180, 202, 221, 241, 269, 298, 329, 360, 392, 425, 459, 494, 530]
            ],
            value2: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [11, 18, 24, 28, 32, 35, 38, 41, 44, 46, 49, 51, 53, 55, 57, 59, 61, 63, 65, 66, 68, 70],
                [11, 30, 43, 52, 60, 67, 74, 80, 85, 90, 95, 100, 104, 108, 113, 116, 120, 124, 128, 131, 134, 138],
                [11, 32, 55, 71, 84, 96, 106, 106, 124, 132, 139, 146, 153, 159, 166, 172, 178, 183, 189, 194, 199, 204],
                [11, 32, 60, 85, 104, 120, 134, 147, 159, 170, 180, 190, 199, 208, 217, 225, 233, 241, 248, 255, 262, 269],
                [11, 32, 60, 92, 119, 141, 159, 176, 192, 206, 219, 232, 244, 255, 266, 276, 286, 296, 305, 314, 323, 332],
                [11, 32, 60, 92, 128, 156, 180, 202, 221, 239, 255, 271, 285, 299, 313, 325, 338, 349, 361, 372, 383, 393],
                [11, 32, 60, 92, 129, 166, 197, 223, 247, 268, 288, 307, 324, 341, 357, 372, 387, 401, 414, 428, 440, 453],
                [11, 32, 60, 92, 129, 170, 208, 241, 269, 295, 318, 340, 361, 380, 399, 417, 434, 450, 466, 481, 496, 510],
                [11, 32, 60, 92, 129, 166, 214, 253, 287, 317, 345, 371, 395, 417, 438, 459, 478, 497, 515, 533, 550, 566],
                [11, 32, 60, 92, 129, 166, 214, 260, 301, 336, 368, 398, 425, 451, 475, 499, 521, 542, 563, 582, 601, 620],
                [11, 32, 60, 92, 129, 166, 214, 262, 309, 351, 388, 422, 453, 482, 510, 536, 561, 585, 608, 630, 651, 672],
                [11, 32, 60, 92, 129, 166, 214, 262, 313, 361, 403, 442, 477, 510, 541, 571, 598, 625, 651, 675, 699, 722],
                [11, 32, 60, 92, 129, 166, 214, 262, 277, 325, 375, 427, 482, 538, 597, 658, 720, 785, 851, 919, 989, 1060]
            ],
            value3: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [16, 28, 36, 42, 48, 53, 58, 62, 66, 70, 73, 76, 80, 83, 86, 89, 92, 94, 97, 100, 102, 105],
                [16, 45, 64, 78, 90, 101, 110, 119, 128, 135, 143, 150, 156, 163, 169, 175, 180, 186, 191, 197, 202, 207],
                [16, 48, 83, 107, 127, 144, 159, 173, 185, 197, 209, 219, 229, 239, 249, 258, 266, 275, 283, 291, 299, 306],
                [16, 48, 90, 128, 156, 180, 202, 221, 239, 255, 271, 285, 299, 313, 325, 338, 349, 361, 372, 383, 393, 403],
                [16, 48, 90, 138, 178, 211, 239, 264, 288, 309, 329, 348, 365, 382, 399, 414, 429, 444, 458, 472, 485, 498],
                [16, 48, 90, 138, 191, 234, 271, 303, 331, 358, 383, 406, 428, 449, 469, 488, 506, 524, 541, 558, 574, 590],
                [16, 48, 90, 138, 193, 250, 295, 335, 370, 403, 432, 460, 487, 512, 535, 558, 580, 601, 622, 641, 660, 679],
                [16, 48, 90, 138, 193, 255, 313, 361, 403, 442, 477, 510, 541, 571, 598, 625, 651, 675, 699, 722, 744, 766],
                [16, 48, 90, 138, 193, 255, 321, 380, 431, 476, 518, 556, 592, 626, 658, 688, 718, 746, 773, 799, 825, 849],
                [16, 48, 90, 138, 193, 255, 321, 391, 451, 504, 552, 597, 638, 677, 713, 748, 781, 813, 844, 874, 902, 930],
                [16, 48, 90, 138, 193, 255, 321, 392, 464, 526, 582, 633, 679, 723, 765, 804, 841, 877, 912, 945, 977, 1008],
                [16, 48, 90, 138, 193, 255, 321, 392, 469, 541, 605, 663, 716, 766, 812, 856, 898, 938, 976, 1013, 1048, 1083],
                [16, 48, 90, 138, 193, 255, 321, 392, 469, 541, 605, 663, 722, 807, 895, 986, 1080, 1177, 1276, 1378, 1483, 1590]
            ]
        },
        ZD: 120.5 // Cao trình đáy tràn sông lũy
    },

    // Hồ Dương Đông
    {
        group_id: 'WFYOUGL1',
        V: {
            z: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
            value: [0.000, 0.000, 0.002, 0.008, 0.025, 0.063, 0.131, 0.238, 0.388, 0.577, 0.802, 1.062, 1.355, 1.679, 2.040, 2.436, 2.859, 3.313, 3.796, 4.306, 4.841, 5.397, 5.973, 6.567, 7.178, 7.807, 8.453, 9.116, 9.796, 10.493, 11.208, 11.942, 12.695, 13.468, 14.261]
        },
        D: {
            z: [0, 14.60, 16.00, 18.00, 20.00, 22.00, 24.00, 26.00, 28.77, 30.00, 31.10,],
            dr: [0, 0.15, 0.2, 0.3, 0.45, 0.6],
            value: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0.06, 0.1, 0.14, 0.16, 0.19, 0.21, 0.23, 0.25, 0.26, 0.27],
                [0, 0.1, 0.16, 0.22, 0.26, 0.3, 0.33, 0.36, 0.4, 0.42, 0.43],
                [0, 0.21, 0.33, 0.45, 0.55, 0.63, 0.7, 0.76, 0.84, 0.88, 0.9],
                [0, 0.36, 0.57, 0.78, 0.94, 1.08, 1.2, 1.31, 1.45, 1.51, 1.56],
                [0, 0.45, 0.71, 0.97, 1.17, 1.34, 1.49, 1.63, 1.81, 1.88, 1.94]
            ]
        },
        S: {
            z: [0, 25, 25.5, 26, 26.5, 27, 27.5, 28, 28.5, 28.77, 29, 29.5, 30],
            dr: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.47, 5, 5.5],
            value2: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 9.81, 14.27, 17.65, 20.49, 22.99, 25.25, 27.33, 29.27, 30.26, 31.08, 32.8, 34.44],
                [0, 9.81, 24.09, 31.62, 37.72, 42.99, 47.7, 52.01, 56, 58.05, 59.73, 63.26, 66.6],
                [0, 9.81, 24.09, 41.62, 51.56, 59.95, 67.35, 74.06, 80.24, 83.39, 86, 91.42, 96.55],
                [0, 9.81, 24.09, 41.62, 61.79, 73.78, 84.16, 93.47, 101.99, 106.33, 109.9, 117.31, 124.31],
                [0, 9.81, 24.09, 41.62, 61.79, 84.28, 98.05, 110.22, 121.26, 126.85, 131.45, 140.96, 149.93],
                [0, 9.81, 24.09, 41.62, 61.79, 84.28, 108.86, 124.22, 138.01, 144.96, 150.64, 162.38, 173.4],
                [0, 9.81, 24.09, 41.62, 61.79, 84.28, 108.86, 135.37, 152.19, 160.6, 167.46, 181.56, 194.75],
                [0, 9.81, 24.09, 41.62, 61.79, 84.28, 108.86, 135.37, 163.69, 173.71, 181.84, 198.46, 213.97],
                [0, 9.81, 24.09, 41.62, 61.79, 84.28, 108.86, 135.37, 163.69, 183.63, 193.08, 212.3, 230.08],
                [0, 9.81, 24.09, 41.62, 61.79, 84.28, 108.86, 135.37, 163.69, 183.63, 193.08, 225.39, 245.93],
                [0, 9.81, 24.09, 41.62, 61.79, 84.28, 108.86, 135.37, 163.69, 183.63, 193.08, 225.39, 258.6]
            ]
        }
    },

    //  Hồ Cây Mến
    {
        group_id: 'IXC8QY8W',
        V: {
            z: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
            value: [0, 0.05, 0.38, 1.4, 4.2, 10.22, 20.02, 33.36, 49.86, 69.23, 91.14, 115.42, 142.08, 171.07, 202.49, 236.35, 272.58, 311.2, 352.27, 395.79, 441.8, 490.29, 541.18, 594.47]
        },
        Free: {
            z: [21.10, 21.15, 21.20, 21.25, 21.45, 21.50, 21.90, 21.95, 22.00, 22.05, 22.10, 22.15, 22.20, 22.25, 22.30],
            value: [0.00, 0.71, 2.02, 3.71, 13.21, 16.14, 45.64, 49.99, 54.46, 59.06, 63.78, 68.63, 73.59, 78.66, 83.85]
        }
    }
]